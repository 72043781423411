<template>
  <!-- 培训记录详情 -->
  <div class="trainingDetails">
    <van-nav-bar :title="$t('trainRecordsDetails.TrdTxt1')"
                 left-text=""
                 left-arrow
                 @click-left="comeBack"
                 :fixed="true" />
    <div class="fixedH"
         style="height: 1.22667rem;"></div>
    <div class="middle">
      <van-cell class="first_cell"
                :title="obj.coursename"
                :label="$t('trainRecordsDetails.TrdTxt2')+obj.empname"
                :value="obj.trainscore" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt3')"
                :value="obj.listno" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt4')"
                :value="obj.yymmdd" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt5')"
                :value="obj.empcode" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt6')"
                :value="obj.empname" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt7')"
                :value="obj.deptname" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt8')"
                :value="obj.staname" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt9')"
                :value="obj.traintype" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt10')"
                :value="obj.trainstyle" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt11')"
                :value="obj.plantype" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt12')"
                :value="obj.trainlevel" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt13')"
                :value="obj.signtype" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt14')"
                :value="obj.signbt" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt15')"
                :value="obj.signet" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt16')"
                :value="obj.signres" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt17')"
                :value="obj.testres" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt18')"
                :value="obj.testbt" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt19')"
                :value="obj.testet" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt20')"
                :value="obj.planbt+'-'+obj.planet" />
    </div>
    <van-divider />
  </div>
</template>

<script>
import { getTrainRecordInfo } from "@api/wxpx.js";
export default {
  data () {
    return {
      result: "",
      obj: {

      }
    };
  },
  methods: {
    comeBack () {
      this.$router.push({ path: "/trainRecords" });
    },
  },
  created () {
    getTrainRecordInfo({
      autoid: this.$route.query.autoid,
    }).then((res) => {
      console.log(res);
      this.obj = res.data[0];
    });
  },
};
</script>

<style lang="less" scoped>
.trainingDetails {
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  // background: #eceaea;
  background: #ffffff;
  .van-icon {
    position: absolute;
    top: 50px;
    left: 30px;
    font-size: 38px;
  }
  .headerImg {
    height: 472px;
  }
  .middle {
    .first_cell {
      .van-cell__title {
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        line-height: 68px;
        color: #333333;
      }
      .van-cell__label {
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 54px;
        color: #666666;
      }
      .van-cell__value {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 48px;
        color: #999999;
      }
    }
    .first_cell::after {
      border-bottom: unset;
    }
    .last_cell {
      .van-cell__title {
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 54px;
        color: #333333;
      }
      .van-cell__value {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 48px;
        color: #666666;
      }
    }
  }
  .van-divider {
    padding: 0 30px;
  }
  .footer {
    .title {
      text-align: center;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 62px;
      color: #333333;
    }
    .content {
      padding: 0 30px;
      text-indent: 2em;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 54px;
      color: #333333;
    }
    .result {
      text-align: center;
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 68px;
      color: #666666;
    }
  }
}
</style>